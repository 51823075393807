import http from '../shared_client_utils/http';

export default {
  fetchIdsBySlugs: (slugs, businessId) => {
    const params = { slugs, businessId };
    return http.get(`${window.baseApiPath}/booking/get-ids-by-slugs`, { params })
  },
  fetchNearestFreeDayTimes: (options) => {
    return http.get(`${window.baseApiPath}/booking/get-available-dates`, { params: options });
  },
  checkAvailableDates: (data) => {
    const config = {
      data
    };
    return http.post(`${window.baseApiPath}/booking/check-available-dates`, config);
  },
  fetchAppointment: (appointmentId, currentClientTime) => {
    const params = { appointmentId, currentClientTime };
    return http.get(`${window.baseApiPath}/booking/get-appointment`, { params });
  },
  fetchAppointmentAndCustomerByToken: (token, appointmentId, currentClientTime) => {
    const params = { token, appointmentId, currentClientTime };
    return http.get(`${window.baseApiPath}/booking/get-appointment-and-customer`, { params });
  },
  fetchEventsForCalendar: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/booking/get-events-for-calendar`, config);
  },
  runAppointmentChecks: (appointment, auth) => {
    const { token } = auth;
    const config = {
      params: { appointment },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/booking/run-appointment-checks`, config);
  },
  createAppointment: (data) => http.post(`${window.baseApiPath}/booking`, { data }),
  updateAppointment: (data) => http.put(`${window.baseApiPath}/booking`, { data }),
  cancelAppointment: (data, options) => {
    const config = {
      data,
      params: options,
    };

    return http.put(`${window.baseApiPath}/booking/cancel`, config);
  },
  deleteTempUnavailableTime: ({tempUnavailableId}) => {
    const config = {
      data: {tempUnavailableId}
    };
    return http.post(`${window.baseApiPath}/booking/delete-temp-unavailable-time/${tempUnavailableId}`, config);
  },
  createTempUnavailableTime: (data) => {
    const config = {
      data
    };
    return http.post(`${window.baseApiPath}/booking/create-temp-unavailable-time`, config);
  },
};
